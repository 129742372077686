export default {
  url: "https://sozluk.gov.tr",

  /**
   * fetch() will only reject a promise if the user is offline,
   * or some unlikely networking error occurs, such a DNS lookup failure.
   * However, there is a simple `ok` flag that indicates
   * whether an HTTP response's status code is in the successful range.
   */
  _handleResponse(response) {
    return response.json().then((json) => {
      if (response.ok && !json.error) {
        return json;
      } else {
        return Promise.reject(
          Object.assign({}, json, {
            status: response.status,
            statusText: response.statusText,
            message: json.error || "Beklenmedik bir hata oluştu.",
          })
        );
      }
    });
  },

  _pointer(_endpoint) {
    return `${this.url}/${_endpoint}`;
  },

  /**
   * Get abstraction.
   * @return {Promise}
   */
  get(_endpoint) {
    return window
      .fetch(this._pointer(_endpoint), {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
        }),
      })
      .then(this._handleResponse);
    // .catch(error => { throw new Error(error) });
  },

  searchForWord(_searchTerm) {
    return this.get(`gts?ara=${_searchTerm}`).then((results) =>
      this.parseResults(results)
    );
  },

  /**
   * Post abstraction.
   * @return {Promise}
   */
  // post(_endpoint, _body) {
  //   return window.fetch(this.url + _endpoint, {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json' },
  //     body: _body,

  //   })
  //     .then(this._handleError)
  //     .catch(error => { throw new Error(error) });
  // }

  parseResults(_results) {
    const results = [];

    for (const resultIndex in _results) {
      const objects = _results[resultIndex];
      const meanings = objects.anlamlarListe;

      let firstFeature = "";
      if (
        Object.prototype.hasOwnProperty.call(meanings[0], "ozelliklerListe")
      ) {
        firstFeature = meanings[0].ozelliklerListe[0].tam_adi;
      }

      meanings.map((v) => {
        let features = firstFeature;
        if (v.ozelliklerListe) {
          features = v.ozelliklerListe.map((value) => value.tam_adi).join(", ");

          if (firstFeature && !features.includes(firstFeature)) {
            features = firstFeature + ", " + features;
          }
        }
        results.push({
          meaning: v.anlam,
          features: features,
        });
      });
    }

    return results;
  },
};
