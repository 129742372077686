<template>
  <SearchBox />
  <div class="home">
    <Status />
    <Results />
  </div>
</template>

<script>
import Results from "../components/Results";
import SearchBox from "../components/SearchBox";
import Status from "../components/Status";

export default {
  name: "Home",
  components: {
    Results,
    SearchBox,
    Status,
  },
};
</script>

<style lang="sass" scoped>
.home
  text-align: center
</style>
