<template>
  <header id="title">
    <router-link to="/">
      <h1>Sözlük</h1>
    </router-link>
  </header>
  <section id="view">
    <router-view />
  </section>

  <footer>
    <div id="nav">
      <router-link to="/">anasayfa</router-link> |
      <router-link to="/hakkinda">hakkında</router-link>
      <br />
      <router-link class="highlighted" to="/elma-sozluk"
        >🍏 gelişmiş mobil sözlük</router-link
      >
    </div>
    <div>Eyüp Can Elma - <a href="https://elma.dev/tr">elma.dev/tr</a></div>
    <small>{{ version }}</small>
  </footer>
</template>

<script>
export default {
  data: function () {
    return { version: process.env.VUE_APP_VERSION };
  },
};
</script>

<style lang="scss">
@import "@/assets/style.scss";
</style>
