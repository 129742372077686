<template>
  <div class="single-result">
    <span class="meaning">
      <b>{{ index + 1 }}.</b>
      {{ result.meaning }}
    </span>
    <span class="features">{{ result.features }}</span>
  </div>
</template>

<script>
export default {
  name: "Result",
  props: ["result", "index"],
};
</script>

<style lang="scss" scoped></style>
