<template>
  <h2>Sayfa bulunamadı</h2>
  <p>Aradığınız sayfa aslında hiç var olmamış olabilir mi?</p>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style lang="scss" scoped></style>
