<template>
  <div v-show="statusText" class="status-text">
    <p>{{ statusText }}</p>
  </div>
</template>

<script>
// import store from '../store'
import { mapState } from "vuex";

export default {
  name: "Status",
  computed: {
    ...mapState(["statusText"]),
  },
};
</script>

<style lang="scss" scoped></style>
