import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home";
import About from "../views/About";
import ElmaSozluk from "../views/ElmaSozluk";
import NotFound from "../views/NotFound";

const routes = [
  {
    path: "/",
    name: "anasayfa",
    component: Home,
  },
  {
    path: "/hakkinda",
    name: "hakkında",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () =>
    //   import(/* webpackChunkName: "about" */ "../views/About.vue"),
    component: About,
  },
  {
    path: "/elma-sozluk",
    name: "çok çok daha gelişmiş sözlük",
    component: ElmaSozluk,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "hata - 404",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById("view").scrollTo({ top: 0, behavior: "smooth" });
    return {
      behavior: "smooth",
      top: 0,
    };
  },
});

export default router;
