<template>
  <div class="results" v-if="results.length">
    <Result
      v-for="(result, index) in results"
      :key="index"
      :result="result"
      :index="index"
    ></Result>
  </div>
</template>

<script>
import Result from "./Result";
import { mapState } from "vuex";

export default {
  name: "Results",
  computed: {
    ...mapState(["results"]),
  },
  components: {
    Result,
  },
};
</script>

<style lang="scss" scoped></style>
