import { createLogger, createStore } from "vuex";
import {
  SET_SEARCH_TERM,
  SET_STATUS_TEXT,
  SET_RESULTS,
  SET_DEBOUNCE,
} from "./mutation-types";
import API from "../api";

const debug = process.env.NODE_ENV !== "production";

export default createStore({
  state: {
    debounce: null,
    searchTerm: "",
    statusText: "",
    results: [],
  },
  mutations: {
    [SET_SEARCH_TERM](state, searchTerm) {
      state.searchTerm = searchTerm;
    },
    [SET_RESULTS](state, results) {
      state.results = results;
    },
    [SET_STATUS_TEXT](state, statusText) {
      state.statusText = statusText;
    },
    [SET_DEBOUNCE](state, debounce) {
      state.debounce = debounce;
    },
  },
  actions: {
    updateSearchTerm({ dispatch, commit }, payload) {
      commit(SET_SEARCH_TERM, payload);
      dispatch("debouncedResults", payload);
    },

    debouncedResults({ state, commit }, payload) {
      // clear the previous debounce so they don't accumulate
      clearTimeout(state.debounce);

      // if empty string, clear the status text immediately and exit
      if (!payload.trim()) {
        commit(SET_STATUS_TEXT, "");
        commit(SET_RESULTS, []);
        return;
      }
      // set the status text (then we will wait)
      commit(SET_STATUS_TEXT, "Yazmayı bitirmeni bekliyorum...");
      commit(SET_RESULTS, []);

      // set the new debounce
      let debounce = setTimeout(() => {
        // once time is up, clear the status text
        // and get the results from the api
        API.searchForWord(payload)
          .then((results) => {
            commit(SET_STATUS_TEXT, "");
            commit(SET_RESULTS, results);
          })
          .catch((error) => {
            commit(SET_STATUS_TEXT, error.message);
          });
      }, 500);
      commit(SET_DEBOUNCE, debounce);
    },

    updateStatusText({ commit }, payload) {
      commit(SET_STATUS_TEXT, payload);
    },

    updateResults({ commit }, payload) {
      commit(SET_RESULTS, payload);
    },
  },
  modules: {},
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
