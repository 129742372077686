<template>
  <div class="elma-sozluk">
    <h2 class="text-center">🍏 Elma Sözlük</h2>
    <h4 class="text-center">En güncel, modern Türkçe sözlük</h4>
    <p>
      Bu site sizin için faydalıysa ve bir Android telefonunuz varsa, yeni
      kodladığım çok daha gelişmiş sözlük uygulamamı kullanabilirsiniz.
    </p>
    <b>Play Store'da görüntüle: </b><br />
    <a href="https://bit.ly/elmasozluk?source=basit-sozluk"
      >🍏 Elma Sözlük - En güncel, modern Türkçe sözlük</a
    >
    <br />
    <br />
    <div class="features">
      <h3>Özellikler</h3>
      <ul>
        <li>📚 93 bin kelime</li>
        <li>📖 13 bin atasözü ve deyim</li>
        <li>🌘 Karanlık tema desteği</li>
        <li>🔍 Anlık arama önerileri</li>
        <li>🔎 Anlık sonuçlar</li>
        <li>🧠 Hatalı yazıma rağmen ifadeyi tanıyabilme</li>
        <li>👧 Tüm yaşlar için uygun, pratik ve modern bir arayüz</li>
        <li>📊 Canlı istatistikler</li>
        <li>📍 Favoriler</li>
        <li>↩️ Geçmiş kaydı tutabilme</li>
        <li>🔃 Arama sonucunu paylaşabilme</li>
        <li>👓 Erişilebilirlik özellikleri</li>
        <li>📱 Veri dostu</li>
      </ul>
    </div>
    <button class="btn" @click="toggleScreenshots">
      Ekran görüntülerini {{ showScreenshots ? 'gizle' : 'göster' }}
    </button>
    <br /><br />
    <div v-show="showScreenshots" class="screenshots">
      <img src="/images/1.png" alt="" />
      <img src="/images/2.jpg" alt="" />
      <img src="/images/3.png" alt="" />

      <button class="btn" @click="toggleScreenshots">
        Ekran görüntülerini {{ showScreenshots ? 'gizle' : 'göster' }}
      </button>
    </div>
    <!-- https://elma.dev/tr/work/elma-sozluk/ -->
  </div>
</template>

<script>
export default {
  name: 'Elma Sözlük',
  data: function () {
    return { showScreenshots: false }
  },
  methods: {
    toggleScreenshots: function () {
      this.showScreenshots = !this.showScreenshots
    },
  },
}
</script>

<style lang="scss" scoped></style>
