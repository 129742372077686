<template>
  <div class="about">
    <h2 class="text-center">HAKKINDA</h2>
    <p>
      Sözlük, Türk Dil Kurumu'nun halk erişimine açık servislerinden
      faydalanarak, arattığınız ifadenin anlamlarını gösterir.
    </p>
    <p>
      Vue.js'i öğrenirken, öğrendiklerimi kullanarak basit bir örnek uygulama
      yapmak istemiştim. Bu uygulama da yaptığım ilk Vue.js uygulaması.
    </p>
    <p>İlgilenen yazılımcılar için kullandıklarımın bir listesi.</p>
    <ul>
      <li>Vue.js</li>
      <li>Vue Router</li>
      <li>Vuex</li>
      <li>Sass</li>
      <li>GitLab Pages</li>
    </ul>
    <h3>Bilinen Problemler:</h3>
    <p>
      İfadenin özellikleri bazı sonuçlarda hatalı (eksik) gösterilebilir. TDK
      servisi, veritabanından dönen verileri ön yüzde işliyor. Ayıklamayı yapan
      Javascript kodları kriptik bir dille yazılmış denebilir. Dönen verinin
      anahtarlarında da tutarlılık yok veya anlaşılması güç ayrıntılar var ancak
      sadece bazı kelimelerde ortaya çıkıyor. Bu yüzden bahsedilen hatayı da
      yalnızca bazı kelimelerde görebilirsiniz.
    </p>
    <p>
      Bu uygulamaya fazla vakit ayıramadığım için olabildiğince sade sonuçlar
      getireceği noktada bıraktım. Ancak bu hataların olmadığı ve daha gelişmiş
      olan sözlük uygulamam mevcut, görmek için
      <router-link to="/elma-sozluk">tıklayın</router-link>. Bu uygulama farklı
      bir dille yazıldı. Vakit bulunca oradaki ayıklayıcımı Javascript'e döküp
      bu sitede de etkinleştirebilirim.
    </p>
  </div>
</template>

<script>
export default {
  name: "About",
};
</script>

<style lang="scss" scoped></style>
