<template>
  <div class="search-box">
    <input
      type="text"
      autocomplete="off"
      autocorrect="off"
      :value="searchTerm"
      @input="(e) => (searchTerm = e.target.value)"
      placeholder="Bir kelime yazınız..."
      autofocus
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'SearchBox',
  data: function () {
    return {
      debounce: null,
    }
  },
  computed: {
    searchTerm: {
      get() {
        return this.$store.state.searchTerm
      },
      set(value) {
        this.updateSearchTerm(value)
      },
    },
  },
  methods: {
    ...mapActions(['updateSearchTerm', 'updateStatusText']),
  },
}
</script>

<style lang="scss" scoped></style>
